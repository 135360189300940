import { FieldType, IField } from "../../../shared/components/DataForm";
import { IColumn } from "@fluentui/react";
import classNames from "./ErrorCorrection.module.scss";
import { startCase } from "../../../shared/helpers/miscHelper";
import { IDomains, ISelectedSource, ISelectedTransmission } from "../../app/App.types";
import {
  defaultChannelErrorColumns,
  defaultSellinErrorColumns,
  selectedChannelErrorColumnsName,
  selectedSellinErrorColumnsName,
} from "../../app/UserOptions.helper";
import { ProductTranslationType } from "./ProductErrorDialog";

export const MsgIDSuffix = "MsgID";
export const FixLevelSuffix = "FixLevel";
export const IsValueChangedSuffix = "IsValueChanged";
export const ValueChangedSuffix = "ValueChanged";
export const OriginalPrefix = "original";

export interface IErrorCategorySetting {
  errorTypeIds: number[];
  targetColumns: string[];
  displayColumns?: string[];
}

export const channelFileFixLevelColumns: string[] = [
  "MatchedPartNumber",
  "ShipToCountryCode",
  "ShipFromCountryCode",
  "InvoiceDate",
];

export const channelSourceFixLevelColumns: string[] = ["MatchedPartNumber", "ShipToCountryCode", "ShipFromCountryCode"];

export const channelKeepAsIsColumns: string[] = [];

export const channelForceColumns: string[] = [];

export const sellinFileFixLevelColumns: string[] = ["BillingSystemCode", "InvoiceDate", "GKProgramCode", "GKItemID"];

export const sellinSourceFixLevelColumns: string[] = ["BillingSystemCode", "GKProgramCode", "GKItemID"];

export const sellinKeepAsIsColumns: string[] = ["InvoiceDate"];

export const sellinForceColumns: string[] = ["GKProgramCode", "GKItemID"];

export const errorCategorySettings: IErrorCategorySetting[] = [
  {
    errorTypeIds: [1, 104],
    targetColumns: ["MatchedPartNumber"],
    displayColumns: ["SourceSKU", "SourceUPC", "SourceProductID", "ReportEndDate", "SourceProductDesc"],
  },
  {
    errorTypeIds: [106],
    targetColumns: ["SellUnitPrice"],
    displayColumns: ["SourceSKU", "SourceUPC", "SourceProductID", "MatchedPartNumber", "PricingMethod", "Currency"],
  },
  {
    errorTypeIds: [102],
    targetColumns: ["InvoiceDate"],
    displayColumns: ["ReportEndDate", "SourceSKU"],
  },
  {
    errorTypeIds: [123],
    targetColumns: ["ShipToCountryCode"],
    displayColumns: ["SourceSKU", "MatchedPartNumber", "Currency", "BillToCountryCode", "SellFromCountryCode"],
  },
  {
    errorTypeIds: [124],
    targetColumns: ["ShipFromCountryCode"],
    displayColumns: ["SourceSKU", "MatchedPartNumber", "Currency", "BillToCountryCode", "SellFromCountryCode"],
  },
  {
    errorTypeIds: [125],
    targetColumns: ["SellFromCountryCode"],
    displayColumns: ["SourceSKU", "MatchedPartNumber", "Currency", "BillToCountryCode", "ShipFromCountryCode"],
  },
];

export const isSearchTextFound = (field, fieldName, searchText) =>
  field?.hasOwnProperty(fieldName) &&
  field[fieldName]?.toString().toLowerCase().indexOf(searchText?.toLowerCase()) >= 0;

export const isSearchTextFoundInColumns = (field, columns, searchText) => {
  let isFound = false;

  for (let i = 0; i < columns?.length; i++) {
    if (isSearchTextFound(field, columns[i].fieldName, searchText)) {
      isFound = true;
      break;
    }
  }

  return isFound;
};

export const getSourceName = (domains: IDomains, sourceOrgID: number): string => {
  let targetSource = domains?.sourceOrganizations.find((source) => source["SourceOrgID"] === sourceOrgID);
  return targetSource && targetSource["Name"];
};

export const getFilteredItems = (items, searchText, columns, selectedValues, domains, selectedErrorRecord?) => {
  var resultItems = items?.map((i) => ({ ...i, key: i.TransmissionID + "_" + i.TransmissionRowID }));
  resultItems =
    searchText && items?.length
      ? items.filter((i) => isSearchTextFoundInColumns(i, columns, searchText))
      : items?.slice();

  resultItems = resultItems?.map((filteredItem) => {
    let resultItem = { ...filteredItem, SourceName: getSourceName(domains, filteredItem["SourceOrgID"]) },
      selectedValue = selectedValues?.find(
        (selectedValue) =>
          selectedValue.transmissionId === filteredItem.TransmissionID &&
          selectedValue.transmissionRowId === filteredItem.TransmissionRowID
      );

    // Merge the selected value with the items if the corresponding value is defined.
    if (selectedValue) {
      resultItem = {
        ...resultItem,
        ...selectedValue,
      };
    }

    // For each target column, check if the value should be marked "changed".
    columns
      ?.filter((column) => column.className === classNames.targetColumn)
      .forEach((column) => {
        let targetFieldName = column.fieldName,
          originalFieldName = OriginalPrefix + targetFieldName,
          fixLevelFieldName = targetFieldName + FixLevelSuffix,
          valueChangedFieldName = targetFieldName + IsValueChangedSuffix,
          msgIdFieldName = lookupMsgIdFieldName(targetFieldName);

        let selectedValue;

        if (selectedValues) {
          selectedValue = selectedValues.find((selectedValue) => {
            switch (selectedValue.ProductTranslationTypeID) {
              case ProductTranslationType.UPC:
                originalFieldName = "originalSourceUPC";
                targetFieldName = "SourceUPC";
                break;

              case ProductTranslationType.SKU:
                originalFieldName = "originalMatchedPartNumber";
                targetFieldName = "MatchedPartNumber";
                break;

              case ProductTranslationType.ProductID:
                originalFieldName = "originalSourceProductID";
                targetFieldName = "SourceProductID";
                break;

              default:
                originalFieldName = "originalSourceProductID";
                targetFieldName = "SourceProductID";
            }

            if (
              selectedValue.transmissionId === filteredItem.TransmissionID &&
              selectedValue.transmissionRowId === filteredItem.TransmissionRowID
            ) {
              return true;
            } else if (
              selectedValue.transmissionId === filteredItem.TransmissionID &&
              selectedValue[fixLevelFieldName] === "F" &&
              selectedValue[originalFieldName] === filteredItem[targetFieldName]
            ) {
              return true;
            } else if (
              selectedValue.sourceOrgId === filteredItem.SourceOrgID &&
              selectedValue[fixLevelFieldName] === "S" &&
              selectedValue[originalFieldName] === filteredItem[targetFieldName]
            ) {
              return true;
            } else {
              return false;
            }
          });
        }

        // This is for cases where fix level is set for the related non-selectedValue rows.
        if (selectedValue && selectedValue[targetFieldName]) {
          resultItem[valueChangedFieldName] = true;
          resultItem[fixLevelFieldName] = selectedValue[fixLevelFieldName];
          resultItem[targetFieldName] = selectedValue[targetFieldName];

          if (selectedValue[msgIdFieldName] && selectedValue[fixLevelFieldName]) {
            resultItem[msgIdFieldName + ValueChangedSuffix] = selectedValue[msgIdFieldName];
          }
        }
      });

    return resultItem;
  });

  return resultItems;
};

const generalColumnWidth = 80;
const dropdownColumnWidth = 140;
const dateColumnWidth = 140;

export const getColumn = (fieldName: string, props: object = {}): IColumn => {
  let headerClassName =
      props["className"] === classNames.targetColumn ? classNames.targetColumnHeader : classNames.errorColumnHeader,
    maxWidth = props["maxWidth"],
    displayName = props["name"] || startCase(fieldName);

  // Use smaller width for dropdown column
  getFieldDomainName(fieldName) && (maxWidth = dropdownColumnWidth);
  fieldName.endsWith("Date") && (maxWidth = dateColumnWidth);

  return {
    key: fieldName,
    fieldName,
    name: displayName,
    ariaLabel: displayName,
    isResizable: true,
    headerClassName,
    ...props,
    minWidth: props["minWidth"] || generalColumnWidth,
    maxWidth, // Set after props to override with for dropdown column.
  };
};

export const lookupMsgIdFieldName = (fieldName: string): string =>
  fieldName === "GKProgramCode" || fieldName === "GKItemID" ? "GK" + MsgIDSuffix : fieldName + MsgIDSuffix;

export const lookupPotentialTargetErrorFields = (fieldName: string): string[] => {
  let potentialTargets = [fieldName.substring(0, fieldName.indexOf(MsgIDSuffix))];

  if (potentialTargets[0] === "GK") {
    potentialTargets = ["GKProgramCode", "GKItemID"];
  }

  return potentialTargets;
};

const targetColumnProps = { className: classNames.targetColumn, minWidth: 160, maxWidth: 300 };

const getTargetFieldNames = (fileErrors, displayColumns): string[] => {
  let targetFieldNames = [];

  if (fileErrors?.length) {
    let fieldNames = Object.keys(fileErrors[0]);

    fileErrors.forEach((error) => {
      fieldNames.forEach((fieldName) => {
        if (fieldName.endsWith(MsgIDSuffix) && error[fieldName] !== 0 && error[fieldName] !== null) {
          let potentialTargetFieldNames = lookupPotentialTargetErrorFields(fieldName);

          potentialTargetFieldNames.forEach((potentialTargetFieldName) => {
            if (
              targetFieldNames.indexOf(potentialTargetFieldName) < 0 &&
              fieldNames.indexOf(potentialTargetFieldName) >= 0 &&
              displayColumns.indexOf(potentialTargetFieldName) >= 0
            ) {
              targetFieldNames.push(potentialTargetFieldName);
            }
          });
        }
      });
    });
  }

  return targetFieldNames;
};

export const getErrorColumns = (
  errorTypeId: number,
  fileErrors,
  selectedSource: ISelectedSource,
  selectedTransmission: ISelectedTransmission,
  userClass: string
): IColumn[] => {
  let columns: IColumn[] = [];
  let targetSetting = errorCategorySettings.find((s) => s.errorTypeIds.indexOf(errorTypeId) >= 0);
  let channelDisplayColumns: string[] =
    localStorage[selectedChannelErrorColumnsName]?.split(",") || defaultChannelErrorColumns;
  let sellinDisplayColumns: string[] =
    localStorage[selectedSellinErrorColumnsName]?.split(",") || defaultSellinErrorColumns;
  let defaultDisplayColumns = userClass === "C" ? channelDisplayColumns : sellinDisplayColumns;
  let displayColumns = targetSetting?.displayColumns || defaultDisplayColumns;

  // Find the non-zero "MsgID" column and use the corresponding value column as the target column.
  if (fileErrors?.length) {
    let targetFieldNames = targetSetting?.targetColumns || getTargetFieldNames(fileErrors, displayColumns);

    if (targetFieldNames?.length) {
      targetFieldNames.forEach((targetFieldName) => columns.push(getColumn(targetFieldName, targetColumnProps)));
    }

    !selectedSource && columns.push(getColumn("SourceName", { minWidth: 240, maxWidth: 800, flexGrow: 1 }));
    !selectedSource && columns.push(getColumn("SourceOrgID", { minWidth: 80, maxWidth: 80 }));
    !selectedTransmission && columns.push(getColumn("TransmissionID", { name: "File ID", minWidth: 60, maxWidth: 80 }));

    displayColumns
      .filter((column) => targetFieldNames.indexOf(column) < 0)
      .forEach((fieldName: string) => columns.push(getColumn(fieldName)));
  }

  return columns;
};

export const getErrorFields = (errorSummaryItems): IField[] => {
  let categoryOptions = [],
    hasMultiCategories = errorSummaryItems?.length > 1;

  if (hasMultiCategories) {
    categoryOptions = errorSummaryItems
      .filter((i) => i.errorTypeId)
      .map((i) => ({ key: i.errorTypeId, text: i.category }));
  }

  return [
    {
      fieldName: "sourceOrgId",
      fieldType: FieldType.displayOnly,
      label: "Source ID",
      className: classNames.displayField,
      visible: "!!this.sourceOrgId",
    },
    {
      fieldName: "transmissionId",
      fieldType: FieldType.displayOnly,
      label: "File ID",
      className: classNames.displayField,
      visible: "!!this.transmissionId",
    },
    {
      fieldName: hasMultiCategories ? "errorTypeId" : "category",
      fieldType: hasMultiCategories ? FieldType.enumeration : FieldType.displayOnly,
      label: "Error Category",
      options: categoryOptions,
      className: hasMultiCategories ? classNames.dropdownField : classNames.displayField,
      width: hasMultiCategories ? 160 : undefined,
    },
    {
      fieldName: "recordCount",
      fieldType: FieldType.displayOnly,
      label: "Record Count",
      className: classNames.displayField,
    },
    {
      fieldName: "recordReturned",
      fieldType: FieldType.displayOnly,
      label: "Record Returned",
      className: classNames.displayField,
      visible: "this.recordCount != this.recordReturned",
    },
  ];
};

export const getColumns = (data: any[], columns: IColumn[] = [], validFieldNames: string[] = []): IColumn[] => {
  if (data?.length) {
    let fieldNames = Object.keys(data[0]);

    for (let i = 0; i < fieldNames.length; i++) {
      let fieldName = fieldNames[i];

      if (!validFieldNames?.length || !(validFieldNames?.indexOf(fieldName) >= 0)) {
        columns.push(getColumn(fieldName));
      }
    }
  }

  return columns;
};

export const getDataColumns = (data: any[], topFieldNames?: string[]): IColumn[] => {
  let columns: IColumn[] = [];

  if (data?.length) {
    // Handle the top field names first, if any.
    if (topFieldNames?.length) {
      for (let i = 0; i < topFieldNames.length; i++) {
        let fieldName = topFieldNames[i];
        let columnName = fieldName === "PartNumber" ? "SKU" : undefined;

        columns.push(getColumn(fieldName, { className: classNames.targetColumnField, name: columnName }));
      }
    }

    columns = columns.concat(getColumns(data, columns, topFieldNames));
  }

  return columns;
};

export const getProductTranslationTypeField = (selectedValue: number): IField => ({
  fieldName: "ProductTranslationTypeID",
  fieldType: FieldType.enumeration,
  label: "Match To",
  width: "100px",
  valueWidth: "100px",
  defaultValue: selectedValue,
  className: classNames.dropdownField,
  placeholder: "",
  options: [
    { key: 2, text: "SKU" },
    { key: 1, text: "Product ID" },
    { key: 3, text: "UPC" },
  ],
});

export const getErrorRecordKey = (item: object): string =>
  item && `${item["TransmissionID"]}_${item["TransmissionRowID"]}`;

export const getErrorRecordInfo = (selectedErrorRecord, selectedValues) => {
  if (!selectedErrorRecord) return {};

  let sourceOrgId = selectedErrorRecord["SourceOrgID"],
    transmissionId = selectedErrorRecord["TransmissionID"],
    transmissionRowId = selectedErrorRecord["TransmissionRowID"],
    existingValueIndex = selectedValues?.findIndex(
      (v) => v.transmissionId === transmissionId && v.transmissionRowId === transmissionRowId
    );

  return { sourceOrgId, transmissionId, transmissionRowId, existingValueIndex };
};

export const getDomainOptions = (domain: any[], keyName: string, textName: string) =>
  domain?.map((item) => ({ key: item[keyName], text: `${item[keyName]} - ${item[textName]}` }));

export const getTargetDropdownOptions = (domains: IDomains, domainName: string) =>
  domainName === "countries"
    ? getDomainOptions(domains?.countries, "CountryCode", "Name")
    : domainName === "subsidiaries"
    ? getDomainOptions(domains?.subsidiaries, "SubsidiaryCode", "Name")
    : domainName === "currencies"
    ? getDomainOptions(domains?.currencies, "CurrencyCode", "CurrencyName")
    : domainName === "billingSystems"
    ? getDomainOptions(domains?.billingSystems, "BillingSystemCd", "Name")
    : domainName === "sellinRecordTypes"
    ? getDomainOptions(domains?.sellinRecordTypes, "RecordTypeCode", "Description")
    : domainName === "channels"
    ? getDomainOptions(domains?.channels, "ChannelCode", "Description")
    : domainName === "sapSalesOffices"
    ? getDomainOptions(domains?.sapSalesOffices, "SalesOfficeCode", "SalesOfficeName")
    : domainName === "sapReasons"
    ? getDomainOptions(domains?.sapReasons, "ReasonCode", "Description")
    : domainName === "plantCodes"
    ? getDomainOptions(domains?.plantCodes, "ManufacturingSiteCode", "Name")
    : domainName === "licensePrograms"
    ? getDomainOptions(domains?.licensePrograms, "ProgramCode", "ProgramName")
    : domainName === "sapCompanies"
    ? getDomainOptions(domains?.sapCompanies, "CompanyCode", "Name")
    : domainName === "sapBillDocTypeCodes"
    ? getDomainOptions(domains?.sapBillDocTypeCodes, "BillDocTypeCode", "Description")
    : [];

export const getFieldDomainName = (fieldName: string): string =>
  fieldName.endsWith("CountryCode")
    ? "countries"
    : fieldName.endsWith("SubsidiaryCode")
    ? "subsidiaries"
    : fieldName.endsWith("CurrencyCode")
    ? "currencies"
    : fieldName.endsWith("BillingSystemCode")
    ? "billingSystems"
    : fieldName.endsWith("RecordTypeCode")
    ? "sellinRecordTypes"
    : fieldName === "ChannelCode"
    ? "channels"
    : fieldName === "SalesOfficeCode"
    ? "sapSalesOffices"
    : fieldName === "ReasonCode"
    ? "sapReasons"
    : fieldName === "PlantCode"
    ? "plantCodes"
    : fieldName === "GKProgramCode"
    ? "licensePrograms"
    : fieldName === "CompanyCode"
    ? "sapCompanies"
    : fieldName === "BillDocTypeCode"
    ? "sapBillDocTypeCodes"
    : "";

export {};
